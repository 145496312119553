@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow:inset $top $left $blur $color;
    -moz-box-shadow:inset $top $left $blur $color;
    box-shadow:inset $top $left $blur $color;
  } @else {
    -webkit-box-shadow: $top $left $blur $color;
    -moz-box-shadow: $top $left $blur $color;
    box-shadow: $top $left $blur $color;
  }
}

@mixin gradient-radial($innerColor, $outerColor)  {
  background-color: $outerColor;
  background-image: -webkit-gradient(radial, center center, 0, center center, 460, from($innerColor), to($outerColor));
  background-image: -webkit-radial-gradient(circle, $innerColor, $outerColor);
  background-image: -moz-radial-gradient(circle, $innerColor, $outerColor);
  background-image: -o-radial-gradient(circle, $innerColor, $outerColor);
  background-repeat: no-repeat;
}

@mixin center {
  margin-left: auto;
  margin-right: auto;
}

@mixin mobile {
  @media (max-width: $tablet) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $tablet) and (max-width: $desktop) {
    @content;
  }
}

@mixin selection($selectionColor, $highlightColor) {
  &::-moz-selection { background: $highlightColor; color: $selectionColor; }
  &::selection { background: $highlightColor; color: $selectionColor; }
}