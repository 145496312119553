//Colors

$primary-purple: #3A1488;
$darker-purple: #2B0B6C;
$primary-green: #84F9CB;
$dark-purple-text: #590959;
$grey-blue-text: #778CA2;
$panel-background: #532872;
$panel-border: #704F8C;
$placeholder-text-color: #B3ABC5;
$light-green-background: #F7FFFC;
$lighter-grey-blue-text: #9EB3C5;

//Fonts

@font-face {
  font-family: 'Roboto-Thin';
  src: url('/fonts/Roboto-Thin.woff2') format('woff2'),
      url('/fonts/Roboto-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto-Medium';
  src: url('/fonts/Roboto-Medium.woff2') format('woff2'),
      url('/fonts/Roboto-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto-Regular';
  src: url('/fonts/Roboto-Regular.woff2') format('woff2'),
      url('/fonts/Roboto-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto-Light';
  src: url('/fonts/Roboto-Light.woff2') format('woff2'),
      url('/fonts/Roboto-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ProximaNova-Semibold';
  src: url('/fonts/ProximaNova-Semibold.woff2') format('woff2'),
      url('/fonts/ProximaNova-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ProximaNova-Regular';
  src: url('/fonts/ProximaNova-Regular.woff2') format('woff2'),
      url('/fonts/ProximaNova-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter-Bold';
  src: url('/fonts/Inter-Bold.woff2') format('woff2'),
      url('/fonts/Inter-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}