.content {
  background-color: #fff;
  width: 100%;
  z-index: 100;
  position: relative;
  padding-top: 150px;

  @include mobile {
    padding-top: 200px;
  }

  h3 {
    font-family: "ProximaNova-Semibold";
    font-size: 27px;
    color: $dark-purple-text;
    font-weight: normal;

    @include mobile {
      padding-left: 20px;
    }

    @include tablet {
      padding-left: 20px;
    }
  }

  .indent-container {
    padding: 0px 20px;
  }

  .company-description {
    max-width: 668px;
    padding-top: 50px;

    .paragraph {
      font-family: "ProximaNova-Regular";
      font-size: 24px;
      color: $grey-blue-text;
      padding-bottom: 55px;
      line-height: 42px;

      &.last {
        @include mobile {
          padding-bottom: 10px;
        }
      }

      .highlight {
        font-family: "ProximaNova-Semibold";
        color: $dark-purple-text;
      }
    }
  }
  .illustration {
    max-width: 382px;
    width: 100%;
    height: 382px;
    background-image: url("/dave-illustration.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  .section {
    padding-bottom: 20px;
  }
}