.header {
  background-image: radial-gradient(50% 100%, $primary-purple 2%, $darker-purple 99%);
  height: 738px;
  width: 100%;

  .background-header {
    background-image: url('/wavy-background.svg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    background-attachment: fixed;
  }

  .hero-header {
    font-family: 'Inter-Bold';
    font-size: 56px;
    color: #fff;
    text-shadow: (0px 1px 21px rgba(0, 0, 0, .15));
    display: flex;
    justify-content: center;
    position: relative;
    top: 180px;
    @include selection(#fff, $light-green-background);

    @include mobile {
      top: 130px;
    }
  }

  .navigation-container {
    top: 30px;
    position: relative;
  }
}