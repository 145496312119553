.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include mobile {
    padding: 0px 20px;
  }

  @include tablet {
    padding: 0px 20px;
  }

  .navbar-burger {
    color: $primary-green;
    background-color: #0000002e;
    border-radius: 4px;
  }

  .right-col {
    .navbar-burger {
      @include tablet {
        display: none;
      }
    }

    &.dropdown-menu-container {
      @include mobile {
        display: none;

        &.open {
          display: flex;
          position: absolute;
          width: 100%;
          justify-content: center;
          left: 0px;
          top: 75px;
        }
      }
    }

    .dropdown-menu {
      &.open {
        @include mobile {
          position: relative;
          height: 180px;
          background-color: #fff;
          z-index: 5000;
          border-radius: 8px;
          width: 90vw;

          .links {
            display: flex;
            flex-direction: column;
            padding-left: 0px;
            align-items: center;
            padding-top: 10px;

            li.outlined {
              padding-top: 20px;
              
              a {
                color: $primary-green;
              }
            }

            li {
              padding-bottom: 20px;
              padding-left: 0px;
              a {
                color: $dark-purple-text;
              }
            }
          }
        }
      }
    }

    .links {
      display: inline-flex;
      list-style-type: none;

      li {
        padding-left: 35px;

        a {
          color: #fff;
          font-family: "ProximaNova-Regular";
          font-size: 18px;
          text-decoration: none;
          @include selection(#fff, $light-green-background);

          &.outlined {
            border: 2px solid $primary-green;
            padding: 14px 34px;
            border-radius: 8px;
          }

          &:hover {
            color: $primary-green;
          }
        }
      }
    }
  }
}