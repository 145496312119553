html, body {
  height: 100%;
  margin: 0;
  scroll-behavior: smooth;
}

.is-centered-mobile {
  @include mobile {
    justify-content: center;
    display: flex;
  }
}