.block {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 60px;
  padding-top: 40px;

  .block-container {
    background-color: #F7FFFC;
    padding: 30px 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 12px;

    @include mobile {
      padding: 30px 20px;
    }
  }

  .block-content {
    font-family: "ProximaNova-Regular";
    font-size: 24px;
    line-height: 42px;
    color: $lighter-grey-blue-text;
    max-width: 631px;

    @include mobile {
      padding-bottom: 25px;
    }
    .inline-logo {
      top: 3px;
      position: relative;
    }
  }
  .link {
    display: flex;
    flex-direction: row;
    align-self: flex-end;
    font-family: "ProximaNova-Semibold";
    font-size: 20px;
    color: $primary-green;
  }
}