.join-container {
  position: absolute;
  width: 100%;
  z-index: 1000;
  top: 360px;

  @include mobile {
    top: 310px;
  }

  .column {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .form-box {
    background-color: $panel-background;
    box-shadow: 1px 2px 3px 0 rgba(0,0,0,0.10);
    border-radius: 10px;
    width: 90%;
    max-width: 750px;
    min-height: 437px;
    height: auto;
    position: relative;
    background: $panel-background;
    box-shadow: 1px 2px 3px 0 rgba(0,0,0,0.10);
    @include center;
    .form-hero {
      font-family: 'Roboto-Thin';
      font-size: 26px;
      color: #FFFFFF;
      letter-spacing: 0;
      line-height: 34px;
      text-align: center;
      padding-top: 21px;
      padding-bottom: 14px;
      @include selection(#fff, $panel-background);
    }
    .form-description {
      opacity: 0.6;
      font-family: 'Roboto-Light';
      font-size: 16px;
      color: #FFFFFF;
      letter-spacing: 0;
      text-align: center;
      line-height: 24px;
      max-width: 392px;
      @include center;
      @include selection(#fff, $panel-background);
      @include mobile {
        padding: 0px 20px;
      }
    }
    .received-influencer {
      position: relative;
      top: 41px;
      .form-submit-icon {
        text-align: center;
        svg {
          width: 111px;
          fill: #663399;
        }
      }
    }
  }
  .form-submit {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    @include mobile {
      padding-bottom: 25px;
    }

    .send {
      width: 140px;
      height: 42px;
      background-color: $primary-green;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      outline: none;
      -webkit-box-shadow: none !important;
      -moz-box-shadow: none !important;
      box-shadow: none !important;
      border-color: transparent;
      font-family: 'Roboto-Medium';
      font-size: 16px;
      color: $dark-purple-text;
      position: relative;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
      &:focus {
        background-color: $primary-green;
        opacity: 0.6;
        border-color: transparent;
        outline: none;
      }
    }
  }
}
.form-box {
  .influencers-form {
    .form-fields {
      padding: 30px 35px 0px 35px;
    }
    input.form-control {
      width: 100%;
      height: 42px;
      border-radius: 6px;
      border: 2px solid $panel-border;
      background-color: $panel-background;
      color: #ffffff;
      font-family: 'Roboto-Regular';
      font-size: 14px;
      letter-spacing: 0;
      padding-left: 15px;
      padding-top: 0px;
      padding-bottom: 0px;
      box-sizing: border-box;
      @include selection(#fff, $panel-background);

      &:focus {
        outline: none;
        border-color: #B6ADC7;
      }
      &::placeholder {
        color: $placeholder-text-color;
        font-family: 'Roboto-Regular';
        font-size: 14px;
        letter-spacing: 0;
      }
      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 100px $panel-background inset;
        -webkit-text-fill-color: #fff;
      }
    }

    .form-full {
      padding: 0px 35px;

      textarea.form-control {
        height: 74px;
        border-radius: 6px;
        border: 2px solid $panel-border;
        background-color: $panel-background;
        color: #ffffff;
        font-family: 'Roboto-Regular';
        font-size: 14px;
        letter-spacing: 0;
        padding-left: 15px;
        padding-top: 0px;
        padding-bottom: 0px;
        resize: none;
        width: 100%;
        padding-top: 10px;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
         -moz-box-sizing: border-box;
         @include selection(#fff, $panel-background);
  
        &:focus {
          outline: none;
          border-color: #B6ADC7;
        }
        &::placeholder {
          color: $placeholder-text-color;
          font-family: 'Roboto-Regular';
          font-size: 14px;
          letter-spacing: 0;
        }
        &:-webkit-autofill {
          -webkit-box-shadow: 0 0 0 100px $panel-background inset;
          -webkit-text-fill-color: #fff;
        }
      }
    }

    .react-select-container {
      .react-select__control {
        background-color: $panel-background;
        border: 2px solid $panel-border;
        border-radius: 6px;

        .react-select__value-container {
          height: 38px;
          .react-select__placeholder {
            color: $placeholder-text-color;
          }
        }

        .react-select__placeholder, .react-select__single-value {
          color: #fff;
          font-family: 'Roboto-Regular';
          font-size: 14px;
          letter-spacing: 0;
          padding-left: 5px;
        }
      }
  
      input {
        width: 300px;
        height: 33px;
        &:focus {
          outline: none;
        }
      }
      .react-select__menu {
        font-family: 'Roboto-Regular';
        font-size: 14px;
        letter-spacing: 0;
        z-index: 1000;
      }
      .react-select__indicators {
        .react-select__indicator {
          color: #B6ADC7;
        }
        .react-select__indicator-separator {
          background-color: $panel-border;
        }
      }
      .react-select__control--is-focused {
        color: #fff;
        outline: 0 !important;
        border-color: #B6ADC7;
        box-shadow: none;
        .react-select__value-container {
          outline: none;
          color: #fff;
          .react-select__input {
            color: #fff;
            outline: none;
            padding-left: 5px;
          }
        }
      }
    }
  }
  .form-group {
    position: relative;
    padding: 10px 0px;
    &.is-invalid {
      .invalid-feedback {
        position: absolute;
        background-color: #9F0013;
        padding: 10px;
        border-radius: 10px;
        z-index: 100;
        color: #fff;
        font-family: 'Roboto-Regular';
        font-size: 14px;
        opacity: 0.8;
        bottom: -20px;

        &:after {
          content: "";
          position: absolute;
          width: 0;
          height: 0;
          border-width: 10px;
          border-style: solid;
          border-color: transparent transparent #9F0013 transparent;
          top: -19px;
          right: 50px;
          opacity: 0.8;
        }
      }
    }
  }
}
