.footer {
  height: 300px;
  background-color: $darker-purple;
  width: 100%;

  .footer-content {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 90px;
    flex-direction: column;

    .description {
      font-family: 'ProximaNova-Regular';
      font-size: 18px;
      color: #fff;
      padding-top: 30px;

      @include mobile {
        text-align: center;
        line-height: 30px;
      }
    }

    .copyright {
      font-family: 'ProximaNova-Regular';
      font-size: 16px;
      color: #fff;
      padding-top: 25px;
    }
  }
}